import { LogLevel } from '@azure/msal-browser';

const clientId = process.env.NEXT_PUBLIC_CLIENT_ID;
const redirectUri = process.env.NEXT_PUBLIC_REDIRECT_URI;

export const msalConfig = {
	auth  : {
		clientId,
		authority: `https://login.microsoftonline.com/common`,
		redirectUri
	},
	cache : {
		cacheLocation         : 'localStorage',
		storeAuthStateInCookie: false
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				console.debug(message);
			},
			logLevel: LogLevel.Verbose,
			piiLoggingEnabled: false,
		}
	}
};
