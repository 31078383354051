"use client";
import "./globals.css";
import { ContextProvider } from "./context";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import Script from "next/script";
import { msalConfig } from "@/app/_sharepoint/authConfig";

export default function RootLayout({ children }) {
    const msalInstance = new PublicClientApplication(msalConfig);
    return (
        <html lang='en'>
            <head>
            </head>
            <body className='p-2'>
                <Script type='text/javascript' strategy='beforeInteractive' id='clarity-script'>
                    {`(function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);
                        t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];
                        y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "jc3pglw8bw");
                    `}
                </Script>

                <MsalProvider instance={msalInstance}>
                    <ContextProvider>
                        {children}
                    </ContextProvider>
                </MsalProvider>
            </body>
        </html>
    );
}
